const React = require("react")

exports.onClientEntry = () => {
  if(process.env.IN_SITE_MAINTENANCE === "true" && window.location.pathname !== '/maintenance/'){
    window.location = '/maintenance/';
  }
}

// Logs when the client route changes
exports.onRouteUpdate = () => {
  if(process.env.IN_SITE_MAINTENANCE === "true" && window.location.pathname !== '/maintenance/'){
    window.location = '/maintenance/';
  }
}
